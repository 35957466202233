<template>
  <div>
    <Header />
    <main
      class="p-4 py-20 min-h-screen max-w-6xl mx-auto"
      v-if="APIContent.length"
    >
      <h1 class="font-bold uppercase text-brandGrey text-2xl mb-4">
        {{ APIData.title }}
      </h1>
      <div v-html="APIContent" class="space-y-4"></div>
    </main>
    <div v-else class="min-h-screen flex items-center justify-center">
      <Loader />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loader from "./components/Loader";
import API from "./api";
// var _ = require("lodash");

export default {
  name: "BlogPost",
  components: {
    Header,
    Loader,
    Footer,
  },
  data: function() {
    return {
      APIData: {},
      APIContent: {},
    };
  },
  mounted() {
    console.log(this.$route.params.id);
    console.log("****** APP MOUNTED");
    if (!this.APIData.length) {
      this.getAPI().then(() => {
        this.getContent();
      });
    }
  },
  computed: {},
  methods: {
    async getAPI() {
      const response = await API.get(
        `/blog?blog_id=${this.$route.params.id}`,
        { ALWAYSIGNOREAUTH: true }
      );
      this.APIData = response.data[0];
    },
    async getContent() {
      console.log("url")
      console.log(this.APIData.content)
      const response = await API.get(this.APIData.content, { REMOVEPREFIX: true, ALWAYSIGNOREAUTH: true });
      let responseData = response.data;
      this.APIContent = responseData;
    },
  },
};
</script>

<style></style>
